import React from 'react'
import ContactMe from 'components/contact/contact-me';
import SEO from 'components/seo';
import Layout from 'components/layout';
import NextBtn from 'ui/next-btn';
import 'scss/contact.scss'

const ContactsPage = () => {
  return (
    <Layout pageTitle="CONTACT">
      <SEO 
        title="CONTACT"
        image="/img/contacts-preview.png"
        description="Hello! I'm a passionate Web Developer. I'm available on Twitter, LinkedIn, Whatsapp or send Direct Message."
      /> 
      <ContactMe />
      <NextBtn
        domClassesToHide=".header,.contact__container,.next__btn"
        nextBtnTitle="INDEX"
        nextPageLink="/"
        translateAmount="-4"
        rotateAmount="180" />
    </Layout>
  )
}

export default ContactsPage
