import React, { useEffect } from 'react'
import ContactForm from 'contact/contact-form';
import ContactCred from 'contact/contact-cred';
import { showPageWithTransition } from 'Helpers/functions';

const Contact = () => {

  useEffect(() => {
    showPageWithTransition();
  }, []);


  return (
    <div className="contact">
      <div className="contact__container">
        <ContactCred />
        <ContactForm />
      </div>
    </div>
  )
}

export default Contact
