import React, { useEffect, useState } from 'react'
import * as Icon from 'react-bootstrap-icons'
import DynamicIcon from 'ui/dynamic-icon'
// import { Link } from 'gatsby'

const ContactCred = () => {
  const [iconSize, setIconSize] = useState(60);
  const [socials/*, setSocials*/] = useState([
    { id: 1, name: 'LinkedIn', link: 'https://www.linkedin.com/in/aymanelassfar/', icon: Icon.Linkedin },
    { id: 2, name: 'Whatsapp', link: 'https://api.whatsapp.com/send?phone=+212677721366', icon: Icon.Whatsapp },
    { id: 3, name: 'Facebook', link: 'https://web.facebook.com/aymanelassfar/', icon: Icon.Facebook },
    { id: 4, name: 'Twitter', link: 'https://twitter.com/AymaneLassfar', icon: Icon.Twitter },
    { id: 5, name: 'Phone', link: 'tel:+212677721366', icon: Icon.PhoneFill },
    { id: 6, name: 'Email', link: 'mailto:aymanelassfar@outlook.com', icon: Icon.EnvelopeFill },
    { id: 7, name: 'Direct', link: '#', icon: Icon.CursorFill },
  ])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // change icon size depending screen width
      if (window.innerWidth < 480) {
        setIconSize(25);
      } else if (window.innerWidth < 768) {
        setIconSize(45);
      } else {
        setIconSize(60);
      }
    }
  }, [iconSize]);

  const ShowContactForm = (socialName) => {
    if (socialName.toLowerCase() === "direct") {
      let cForm = document.getElementById('contactForm');
      // show contact form
      cForm.setAttribute('style', 'display: flex');
    }
  }


  return (
    <div className="contact__cred">
      
      <h1 className="contact__credtitle">
        We Can Do Something Better Togheter
      </h1>

      {/* SOCIAL LINKS */}
      <div className="contact__credSection">
        {socials.map((social) => 
          <a className="contact__hexFrame" href={social.link} key={`social${social.id}`} 
            onClick={() => {ShowContactForm(social.name)}} target={(social.link !== "#") ? '_blank' : ''} rel="noopener noreferrer">

            <span className="contact__hexIcon">
              <DynamicIcon iconName={social.icon} iconSize={iconSize}></DynamicIcon>
            </span>
            <div className="contact__hexInfo">
              <strong className="contact__hexInfoTitle">{ social.name }</strong>
            </div>
          </a>
        )} 

      </div>
      {/* END SOCIAL LINKS */}

    </div>
  )
}

export default ContactCred
