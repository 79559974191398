import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import * as Icon from 'react-bootstrap-icons'

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSent, setIsSent] = useState(false);
  
  const SendEmail = (e) => {
    e.preventDefault();
    let alertMsg = document.querySelector('#alertMsg');
    if (name && email && subject && message && !isSent) {
      setIsSent(true);
      
      // *** SEND EMAIL
      emailjs.sendForm('service_lassfar_gmail', 'template_8236sea', e.target, 'user_Oaul4Mr4mcHPckEq39wL7')
      .then((result) => {
        //console.log(result.text);
        let alertMsg = document.querySelector('#alertMsg');
        alertMsg.classList.add("fade-in-alert");
        setTimeout(() => {
          alertMsg.classList.add("fade-out-alert");
        }, 5000);
      }, (error) => {
        alert(error.text)
        //console.log(error.text);
      });
      // *** END SEND EMAIL
      
      alertMsg.classList.remove("d-none");
      alertMsg.classList.remove("fade-out-alert");
      alertMsg.classList.remove("bg-redpink");
      alertMsg.className += " fade-in-alert d-flex bg-cyan";
      alertMsg.innerHTML = 'Your message have been submitted <span role="img"> 🔥</span>';
      //console.log("alert class", alertMsg.classList);
      setTimeout(() => {
        alertMsg.classList.remove("d-flex");
        alertMsg.classList.remove("fade-in-alert");
        alertMsg.classList.remove("bg-cyan");
        alertMsg.className += " fade-out-alert d-none";
        //console.log("alert class", alertMsg.classList);
      }, 3000);
    } else if (!name && !email && !subject && !message) {
      alertMsg.classList.remove("d-none");
      alertMsg.classList.remove("fade-out-alert");
      alertMsg.classList.remove("bg-cyan");
      alertMsg.className += " fade-in-alert d-flex bg-redpink";
      alertMsg.innerHTML = 'All fields are required <span role="img"> 😭</span>!!';
      //console.log("alert class", alertMsg.classList);
      setTimeout(() => {
        alertMsg.classList.remove("d-flex");
        alertMsg.classList.remove("fade-in-alert");
        alertMsg.classList.remove("bg-redpink");
        alertMsg.className += " fade-out-alert d-none";
        //console.log("alert class", alertMsg.classList);
      }, 3000);
    } else if (isSent) {
      alertMsg.classList.remove("d-none");
      alertMsg.classList.remove("fade-out-alert");
      alertMsg.classList.remove("bg-redpink");
      alertMsg.className += " fade-in-alert d-flex bg-cyan";
      alertMsg.innerHTML = 'You already sent a message <span role="img"> 🤔</span>!';
      //console.log("alert class", alertMsg.classList);
      setTimeout(() => {
        alertMsg.classList.remove("d-flex");
        alertMsg.classList.remove("fade-in-alert");
        alertMsg.classList.remove("bg-cyan");
        alertMsg.className += " fade-out-alert d-none";
        //console.log("alert class", alertMsg.classList);
      }, 3000);
    }
  }

  
  const HideContactForm = () => {
    let cForm = document.getElementById('contactForm');
    // show contact form
    cForm.classList.add(...['hide-contact-form']);
    setTimeout(() => {
      cForm.setAttribute('style', 'display: none');
      cForm.classList.remove('hide-contact-form');
    }, 1000);
  }

  return (
    <section className="contact__form" id="contactForm">
      {/* CLOSE BTN */}
      <button aria-label="hide form btn" className="header__btn" id="closeContactFormBtn" onClick={HideContactForm}>
        <Icon.X size={40}></Icon.X>
      </button>

      <form onSubmit={ (e) => {SendEmail(e)} } method="POST">

        <h2 className="contact__title">
          Your Message
        </h2>
        <input type="text" className="contact__input" name="name" minLength="5"
          onChange={ (e) => setName(e.target.value) } value={name} maxLength="50" autoComplete="off" placeholder="Fullname" />
        <input type="email" className="contact__input" name="email" minLength="10"
          onChange={ (e) => setEmail(e.target.value) } value={email} maxLength="50" autoComplete="off" placeholder="Email" />
        <input type="text" className="contact__input" name="subject" minLength="10"
          onChange={ (e) => setSubject(e.target.value) } id="subject" value={subject} maxLength="50" autoComplete="off" placeholder="Subject" />
        <textarea className="contact__textarea" name="message"
          onChange={ (e) => setMessage(e.target.value) } value={message} rows="4" maxLength="300" autoComplete="off" placeholder="Message">
          {/* MESSAGE */}
        </textarea>
        <button aria-label="send msg btn" type="submit" className="contact__btn">
          SEND
        </button>
        <div className="contact__alert d-none" id="alertMsg">
          Your message have been submitted <span role="img">🔥</span>
        </div>
      </form>
    </section>
  )
}

export default ContactForm
